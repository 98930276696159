import { IonicSafeString, alertController } from '@ionic/vue';
import { AxiosError } from 'axios';
export function useErrorBox () {
    const showError = async (message: any, title: string, timeOut = 20000, cssClass = 'defaultAlert') => {
        
      // debugger;
      let messageText = "";

      if(message instanceof AxiosError && message.response?.data){
        if(message.response.data.ExceptionMessage){
          messageText = "For admins:<br/>" + message.response.data.ExceptionMessage;
          if(message.response.data.InnerMessages && message.response.data.InnerMessages.length > 0){
            messageText = messageText + "<br/>" + message.response.data.InnerMessages.join("<br/>");
          }

          messageText = messageText + "<br/><br/>For users:<br/>" + message.response.data.Message;

        } else if(message.response.data.Message){
          messageText = message.response.data.Message;
        } else  if(message.response?.data.type === "https://tools.ietf.org/html/rfc7231#section-6.6.1")
          messageText = message.response?.data.title;

      } else if(message instanceof TypeError){
        messageText = (message as TypeError).message;

      } else if(message instanceof Error){
        messageText = (message as Error).message;
      }

      const stringMessage = new IonicSafeString(messageText);

      const alert = await alertController
        .create({
          header: title,
          cssClass: cssClass,
          message: stringMessage,
          buttons: [{text:"OK", role:"Close", cssClass: cssClass + 'Ok'}, {text:"Copy", role:"Copy", cssClass: cssClass + 'Copy'}],
        });
        await alert.present();

        const closeTimeout = setTimeout(() => alert.dismiss(), timeOut);
        alert.onDidDismiss().then(_i=>{ 
          if(_i.role == "Copy") {
            navigator.clipboard.writeText("At " + Date() +  "\nMessage: " + messageText + "\nLocation: " + window.location.href);
          }
          if(closeTimeout){ 
            clearTimeout(closeTimeout);
          }});
      
        return alert;
    };

  return {
    showError
  }
}